import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedMaterialModule } from '../../modules/shared-material/shared-material.module';
import { ClientAreaRoutingModule } from './client-area.routing.module';
import { AuthGuard } from 'src/app/auth.guard';
import { PrizeBoxesComponent } from './prize-boxes/prize-boxes.component';
import { GameClientAreaComponent } from './game-client-area/game-client-area.component';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { SharedComponentsModule } from 'src/app/modules/shared-components/shared-components.module';
import { SharedServicesModule } from 'src/app/modules/shared-services/shared-services.module';
import { ActivePrizesComponent } from './active-prizes/active-prizes.component';
import { BonusQueueComponent } from './bonus-queue/bonus-queue.component';
import { RedeemPromocodeComponent } from './redeem-promocode/redeem-promocode/redeem-promocode.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrizeInfoDialogComponent } from './prize-boxes/prize-info-dialog/prize-info-dialog.component';
import { LotteryLabelComponent } from './prize-boxes/lottery-label/lottery-label.component';
import { LotteryPrizeBoxComponent } from './prize-boxes/lottery-prize-box/lottery-prize-box.component';
import { BonusLotteryWheelComponent } from './bonus-lottery-wheel/bonus-lottery-wheel.component';
import { DesktopMainClientAreaComponent } from './main-client-area/desktop/desktop-main-client-area.component';
import { MobileMainClientAreaComponent } from './main-client-area/mobile/mobile-main-client-area.component';
import { MainClientAreaComponent } from './main-client-area/main-client-area.component';
import {InsertBrBetweenWordsDirective} from "../../insert-br-between-words.directive";

@NgModule({
    declarations: [
        PrizeBoxesComponent,
        MainClientAreaComponent,
        DesktopMainClientAreaComponent,
        MobileMainClientAreaComponent,
        GameClientAreaComponent,
        ActivePrizesComponent,
        BonusQueueComponent,
        RedeemPromocodeComponent,
        PrizeInfoDialogComponent,
        LotteryLabelComponent,
        LotteryPrizeBoxComponent,
        BonusLotteryWheelComponent,
        InsertBrBetweenWordsDirective
    ],
  imports: [
    CommonModule,
    ClientAreaRoutingModule,
    SharedServicesModule,
    SharedComponentsModule,
    SharedMaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    InsertBrBetweenWordsDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AuthGuard],
})
export class ClientAreaModule {

  constructor() {
    console.log('client area module starting');
  }
}

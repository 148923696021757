<app-common-page-title *ngIf="isDesktop else mobile" icon="assets/playbison/icon-my-bonuses.png"> <ng-container i18n>My bonuses</ng-container> </app-common-page-title>
<ng-template #mobile>
  <app-mobile-page-back-header iconName="icon-my-bonuses.png"><ng-container i18n>My bonuses</ng-container></app-mobile-page-back-header>
</ng-template>

<div class="table-container" [ngClass]="isDesktop?'desktop-content':'mobile-content'">

  <ng-container *ngIf="activeBonusList?.length >0">
    <ng-container *ngIf="isDesktop else mobileactive">
      <div class="active-bonus-top-bar">
        <div>
          <img class="badge-active" src="assets/desktop/badge-active.svg">
          <span class="name category-title-h1" style="text-transform: none;">{{ activeBonusList[0].bonus_name ? activeBonusList[0].bonus_name : activeBonusList[0].bonus_ref}}</span>
        </div>
        <div>
          <div class="cancel-bonus">
            <a routerLink="{{ activeBonusList[0].bonus_link }}" class="info-link">i</a>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #mobileactive>
    <div class="active-bonus-top-bar" fxLayoutAlign="space-between center" fxLayout="row">
      <img class="gift-box" src="assets/desktop/badge-active.svg">
      <div fxFlex="65" class="name category-title-h1" i18n>{{ activeBonusList[0].bonus_name ? activeBonusList[0].bonus_name : activeBonusList[0].bonus_ref}}</div>
      <div class="active" i18n>
        <div *ngIf="activeBonusList?.length >0">
          <!-- {{ activeBonusList[0].bonus_name ? activeBonusList[0].bonus_name : activeBonusList[0].bonus_ref}} -->
          <!-- <a (click)="bonusTerms('link')" class="info-link">i</a> -->
          <a routerLink="{{ activeBonusList[0].bonus_link }}" class="info-link">i</a>

        </div>
      </div>
    </div>
  </ng-template>
  <div class="grid-wrapper history top-bar header">
    <div class="header" i18n>Exp. date</div>
    <div class="header" i18n>Amount</div>
    <ng-container *ngIf="isDesktop">
      <div class="header" i18n>Wagered</div>
      <div class="header" i18n>Action</div>
    </ng-container>
  </div>
  <ng-container *ngIf="activeBonusList?.length >0 else nothing">
    <div class="data-wrap">
      <div class="grid-wrapper history top-bar-data" *ngFor="let element of activeBonusList">
        <div class="row1" fxLayoutAlign="center center">{{element.expiry_date}}</div>
        <div class="row1" fxLayoutAlign="center center" fxLayoutGap="4px" fxLayout="row">
          <div>{{element.granted_amount_in_wallet_currency}} {{element.active_bonus_balance.currency}}</div>
          <div *ngIf="element.bonus_name">
            <a routerLink="{{ element.bonus_link }}" class="info-link">i</a>
          </div>
        </div>
        <div [ngClass]="isDesktop?'':'row2'">
          <div *ngIf="!isDesktop" class="wagered-value"><span i18n>Wagered</span>:&nbsp;{{getTournoverPercentage(element)}}%</div>
          <div class="bonus-list-progress-bar b-history">
            <ng-container *ngIf="isDesktop">{{getTournoverPercentage(element)}}%</ng-container>
            <app-progress-bar [value]="getTournoverPercentage(element)"></app-progress-bar>
          </div>
        </div>

        <div class="cancel-bonus" fxLayoutAlign="center center" fxLayout="row">
          <button (click)="cancelBonus()" round-button filled="empty" color="accent" i18n>Cancel</button>
        </div>

      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="bonusQueue?.length > 0">
    <div class="category-title-h1" i18n>Bonus queue:</div>
    <div class="info" *ngIf="!isDesktop" i18n><strong>Note: </strong>If you wish to play bonus you have to wager or cancel the bonus which is marked as active.</div>


    <div class="grid-wrapper header queue">
      <div class="header" i18n>Date</div>
      <div class="header" i18n>Amount</div>
      <ng-container *ngIf="isDesktop">
        <div class="header" i18n>Status</div>
        <div class="header" i18n>Valid</div>
        <div class="header" i18n>Action</div>
      </ng-container>
    </div>

    <ng-container *ngIf="bonusQueue?.length > 0 else nothing">
      <div>
        <div class="grid-wrapper queue" *ngFor="let element of bonusQueue;let index = index;">
          <div class="queue-row1">{{element.enqueueing_date}}</div>
          <div class="queue-row1">{{element.bonus_amount}} {{element.bonus_currency}}</div>
          <ng-container *ngIf="!isDesktop else desktopqueue">
            <div class="queue-row2"><span i18n>Valid for</span>: {{element.days_valid}} days</div>
            <div class="queue-row2 bonus-name"><span i18n>Status</span>: {{ element.bonus_name ? element.bonus_name : element.bonus_ref}}</div>
          </ng-container>
          <ng-template #desktopqueue>
            <div class="queue-row2 p-t-14">{{ element.bonus_name ? element.bonus_name : element.bonus_ref}}</div>
            <div class="queue-row2 p-t-14">{{element.days_valid}} Days</div>
          </ng-template>
          <div class="queue-row3">
            <button (click)="activateBonus(element.id)" round-button filled="empty" color="accent" i18n>Activate</button>
            <div *ngIf="element.bonus_name">
              <a routerLink="{{ element.bonus_link }}" class="info-link">i</a>
            </div>
          </div>
        </div>
      </div>
      <div class="info" *ngIf="isDesktop" i18n>
        <strong>Note: </strong> If you wish to play this bonus you have to wager or cancel the bonus which is marked as active.
      </div>
    </ng-container>
  </ng-container>


  <div class="active-prizes-container">
    <app-active-prizes>
    </app-active-prizes>
  </div>


  <div class="category-title-h1" i18n>Bonus history:</div>

  <div class="grid-wrapper header history">
    <div class="header" i18n>Exp. date</div>
    <div class="header" i18n>Amount</div>
    <ng-container *ngIf="isDesktop">
      <div class="header" i18n>Wagered</div>
      <div class="header" i18n>Action</div>
    </ng-container>
  </div>
  <ng-container *ngIf="bonusList?.length > 0 else nothing">
    <div class="grid-wrapper history" *ngFor="let element of bonusList">
      <div class="row1">{{element.expiry_date}}</div>
      <div class="row1">{{element.granted_amount_in_wallet_currency}} {{element.active_bonus_balance.currency}}</div>
      <div [ngClass]="isDesktop?'':'row2'">
        <div><span i18n>Wagered</span>:&nbsp;{{getTournoverPercentage(element)}}%</div>
        <div *ngIf="element.bonus_name && !isDesktop" class="mobile-info">
          <a routerLink="{{ element.bonus_link }}" class="info-link">i</a>
        </div>
        <!-- <div class="bonus-list-progress-bar b-history">
          <app-progress-bar [value]="getTournoverPercentage(element)"></app-progress-bar>
          <ng-container *ngIf="isDesktop">{{getTournoverPercentage(element)}}%</ng-container>
        </div> -->
      </div>
      <div *ngIf="element.bonus_name && isDesktop">
        <button round-button filled="empty" color="accent" routerLink="{{ element.bonus_link }}" class="info-btn-link" i18n>Info</button>
      </div>
    </div>
  </ng-container>
  <!-- <div class="results-count"><span i18n>All results:</span> {{count}}</div> -->
</div>


<ng-template #nothing>
  <div class="no-prizes" i18n>Your list is empty.</div>
</ng-template>
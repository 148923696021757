<div class="page-body step-body desktop-panel desktop-verify" [ngClass]="isDesktop ? 'desktop': 'mobile'" fxLayoutAdivgn="center center">

    <div *ngIf="!isReedeemCompleted && !isSmsVerification" class="redeem-code-body center" fxLayoutAdivgn="center center">
        <h1 class="page-header">{{page?.header}}
        </h1>
        <div class="page-inner-text" [innerHTML]="body">
        </div>
        <img class="promo-img" src="{{licenceService.getKey('promo-banner')}}">
    </div>
    <div *ngIf="!isReedeemCompleted && !isSmsVerification" class="casino-form-wrapper" fxLayout="row" fxLayoutAlign="center center">
        <div id="casino-form" class="form-custom-design">
            <mat-error *ngIf="hasError" class="error">{{errorMsg}}</mat-error>
            <div fxLayout="row" fxLayoutAlign="center center">
                <form name="redeemCodeForm" [formGroup]="redeemCodeForm" (ngSubmit)="onSubmit(redeemCodeForm)">
                    <mat-form-field appearance="outline">
                        <mat-label i18n>promo code</mat-label>
                        <input matInput type="text" formControlName="code">
                        <img matSuffix src="{{licenceService.getKey('icon-promo')}}" class="promo-icon">
                        <mat-error *ngIf="redeemCodeForm.get('code').hasError('required')" i18n>Please provide a promo code.</mat-error>
                    </mat-form-field>
                    <div class="buttons">
                        <div><button round-button filled="filled" color="accent" type="submit" size="large" [disabled]="!redeemCodeForm.valid" i18n>submit</button></div>
                    </div>

                </form>
            </div>
        </div>

    </div>
    <ng-container *ngIf="isSmsVerification">
        <ng-container *ngTemplateOutlet="smsverification"></ng-container>
    </ng-container>
    <ng-container *ngIf="isReedeemCompleted">
        <ng-container *ngTemplateOutlet="completed"></ng-container>
    </ng-container>
</div>
<ng-template #completed>
    <div class="result" fxLayout="row" fxLayoutAlign="center center" i18n>Promocode applied!</div>
    <div class="complete-msg" fxLayout="column" fxLayoutAlign="center center">
        <img src="{{licenceService.getKey('success-tick')}}">
        <div class="complete-text">
            <p i18n>Your promo code has been applied, check your bonus queue for details.</p>
        </div>
    </div>
    <div class="buttons" [ngStyle]="{'margin': isDesktop ? '40px 0px 0px' : '32px 0px 0px'}">
        <div><button round-button size="large" filled="filled" color="accent" type="submit" [routerLink]="bonusQueueUrl"><span i18n>My Bonuses</span></button></div>
    </div>

</ng-template>

<ng-template #smsverification>
    <ng-container *ngIf="!isChangePhone else changephone">
        <div *ngIf="!isReedeemCompleted" class="redeem-code-body center" fxLayoutAdivgn="center center">
            <h1 class="page-header">{{page?.header}}
            </h1>
            <div class="page-inner-text" [innerHTML]="body">
            </div>
        </div>
        <div class="" fxLayoutAdivgn="center center" fxLayout="column" [ngClass]="isDesktop ? 'desktop': 'mobile'">
            <ng-container>
                <div class="sms-form-wrapper form-custom-design" fxLayoutAlign="center center" fxLayout="column">
                    <div class="promo-value">
                        <p>Promo Code</p>
                        <span>{{redeemCodeForm.value.code}}</span>
                    </div>
                    <div class="verify-code">
                        <img src="assets/icons/icon-info-promo.svg">
                        <p *ngIf="isDesktop">Please enter 5-digit verification code you received via SMS:</p>
                        <p *ngIf="!isDesktop">To use your promo code please enter 5-digit verification code you received via SMS below:</p>
                    </div>
                    <!-- <h3 i18n>Please enter the 5-digit verification code we sent via SMS:</h3>   -->
                    <!-- <span class="additional-info" *ngIf="!detectDesktopService.isDesktop()" i18n>(we want to make sure it's really you)</span> -->
                    <form name="smsForm" [formGroup]="smsForm" (ngSubmit)="onSmsSubmit(smsForm)">
                        <div class="sms-verification-wrapper" fxLayoutGap="5px" fxLayoutAlign="start center" fxLayout="row">
                            <input autocomplete="one-time-code" class="sms-input" #smsinput inputmode="numeric" type="text" pattern="[0-9]{5}" formControlName="code" maxlength="5" autofocus>
                        </div>
                        <!-- <div class="no-code">
                                <span class="additional-info" i18n>We want to make sure it's really you</span>
                            </div> -->
                        <div class="buttons" style="padding-bottom: 0px;margin: 32px 0px 24px !important;">
                            <div><button round-button size="large" filled="filled" color="accent" type="submit"><span i18n>submit</span></button></div>
                        </div>
                        <div class="no-code">
                            <span i18n class="code-question">Didn't receive the code?</span><br />
                            <div class="cta-btns">
                                <button class="link" (click)="resendCode()"><ng-container i18n>Send code again</ng-container>&nbsp;<ng-container *ngIf="timerValue>0">{{timerValue}}s</ng-container></button>
                                <button class="link" (click)="isChangePhone=true" i18n>Change phone number</button>
                            </div>
                            <!-- <img src="{{licenceService.getKey('signup-step-three')}}"> -->
                            <app-signup-steps [step]="'three'"></app-signup-steps>
                        </div>

                    </form>
                </div>
            </ng-container>
        </div>
    </ng-container>

</ng-template>
<ng-template #changephone>
    <div class="redeem-code-body center" fxLayoutAdivgn="center center" fxLayout="column">
        <h1 class="page-header">{{page?.header}}
        </h1>
        <div class="page-inner-text" [innerHTML]="body">
        </div>
    </div>
    <div class="phone-form-wrapper form-custom-design" fxLayoutAdivgn="center center" fxLayout="column">
        <div class="promo-value">
            <p>Promo Code</p>
            <span>{{redeemCodeForm.value.code}}</span>
        </div>
        <div class="verify-code">
            <img src="assets/icons/icon-info-promo.svg">
            <p *ngIf="isDesktop">Please enter your phone number to receive 5-digit verification code via SMS.</p>
            <p *ngIf="!isDesktop">To use your promo code please enter your phone number to receive 5-digit verification code via SMS.</p>
        </div>
        <!-- <div class="info" i18n>
            Please enter your phone number for SMS verification.
        </div> -->
        <div class="change-telephone-form-wrapper">
            <form [formGroup]="changePhoneForm" (ngSubmit)="addPhoneVerification(changePhoneForm)">
                <div class="telephoneContainer" fxLayout="row" fxLayoutAlign="center center">
                    <mat-form-field class="ccode" appearance="outline">
                        <mat-label i18n>country prefix</mat-label>
                        <select matNativeControl name="car" [(ngModel)]="prefixCountry" [ngModelOptions]="{standalone: true}" (change)="triggerTelephoneChange()">
                            <option *ngFor="let code of countryCodes" [value]="code.dial_code">{{ code.dial_code }} {{ code.name }}
                            </option>
                        </select>
                    </mat-form-field>
                    <mat-form-field class="telephone" appearance="outline">
                        <mat-label i18n>telephone number</mat-label>
                        <input matInput [(ngModel)]="telephoneSuffix" [ngModelOptions]="{standalone: true}" name="telephoneSuffix" (change)="triggerTelephoneChange()">
                    </mat-form-field>
                    <div class="errorMsgTelephone">
                        <span class="error" *ngIf="!isTelephoneValid" i18n>Please enter the correct phone number.</span>
                    </div>
                    <input type="hidden" formControlName="telephone" />
                </div>
                <div class="buttons" [ngStyle]="{'margin': isTelephoneValid ? '10px 0 0 0' : '22px 0 0 0'}">
                    <div><button round-button size="large" filled="filled" [class.spinner]="formSubmited" color="accent" type="submit"><span *ngIf="!formSubmited" i18n>submit</span><span *ngIf="formSubmited">&nbsp;</span></button></div>
                </div>
            </form>
        </div>
        <div class="no-code">
            <!-- <img src="{{licenceService.getKey('signup-step-three')}}"> -->
            <app-signup-steps [step]="'three'"></app-signup-steps>
            <button class="link" round-button size="large" filled="empty" color="accent" (click)="isChangePhone=false" i18n>cancel</button>
        </div>
    </div>
</ng-template>

<ng-container *ngIf="pageView === 'OPENCHANGENUMBERMODAL'">
    <div class="success-popup" [ngClass]="{'desktop': isDesktop, 'mobile': !isDesktop}">
        <div class="success-content">
            <div class="success-header">
                <h2 i18n>Success!</h2>
                <app-close-dialog-button [customClass]="'medium'" (closeEvent)="closeDialog()" [position]="true"></app-close-dialog-button>
            </div>
            <div class="success-body">
                <mat-divider></mat-divider>
                <div class="image-box">
                    <img src="{{licenceService.getKey('icon-phone')}}">
                </div>
                <div class="success-txt">
                    <p i18n>Your phone number has been updated!</p>
                </div>
                <div class="success-button">
                    <button round-button filled="empty" color="accent" (click)="closeDialog()" i18n>ok</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="pageView === 'OPENMODAL'">
    <div class="success-popup" [ngClass]="{'desktop': isDesktop, 'mobile': !isDesktop}">
        <div class="success-content">
            <div class="success-header">
                <h2 i18n>Success!</h2>
                <app-close-dialog-button [customClass]="'medium'" (closeEvent)="closeDialog()" [position]="true"></app-close-dialog-button>
            </div>
            <div class="success-body">
                <mat-divider></mat-divider>
                <div class="image-box">
                    <img src="{{licenceService.getKey('success-tick')}}">
                </div>
                <div class="success-txt">
                    <p i18n>Your SMS code has been resent.</p>
                </div>
                <div class="success-button">
                    <button round-button filled="empty" color="accent" (click)="closeDialog()" i18n>ok</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<router-outlet></router-outlet>
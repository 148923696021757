import { Component } from '@angular/core';
import { BasePageComponent } from '../../../base-page/base-page.component';
import { ToggleMenuBackService } from 'src/app/services/utils/toggle-menu-back.service';
import { BestGamesService } from 'src/app/services/client-area/best-games.service';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { GameItem } from 'src/app/apollo/models/base-models';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { AvatarService } from '../../../../services/profile/avatar.service';
import { LoginStatusService } from '../../../../services/auth/login/login-status.service';
import { Subscription } from 'rxjs';
import { GamificationLevelService } from '../../../../services/profile/gamification-level.service';
import { UserBonusLottery, UserChargeback } from '../../../../services/profile/profile.models';
import { ChargebackService } from '../../../../services/profile/chargeback.service';
import { PrizeBoxService } from '../../../../services/client-area/prize-box.service';
import {
  bonusQueueTrx,
  bonusTrx,
  cashbackTrx,
  changePasswordTrx,
  clientAreaTrx,
  editProfileTrx,
  infoTrx,
  lemonLotteryTermsTrx, myGamesTrx,
  prizeBoxTrx,
  scratchCardsTrx,
  treasureBoxesTrx
} from '../../../../router-translation.labels';
import { TranslationConfig } from '../../../../utils/translate-config';
import { BonusLotteryService } from '../../../../services/profile/bonuslottery.service';
import { Router } from '@angular/router';

const LEVEL_NAMES = ['brown', "iron", "silver", "gold", "platinum", "diamond"]

@Component({
  template: '',
})
export class BaseMainClientAreaComponent extends BasePageComponent {

  constructor(
    protected chargebackService: ChargebackService,
    private translationConfig: TranslationConfig,
    private toggleBackService: ToggleMenuBackService,
    private bestGamesService: BestGamesService,
    private deviceDetectService: DetectDeviceService,
    private loginStatusService: LoginStatusService,
    private avatarService: AvatarService,
    private gamificationLevelService: GamificationLevelService,
    private prizeBoxService: PrizeBoxService,
    private bonusLotteryService: BonusLotteryService,
    private router: Router,
  ) {
    super();
  }

  isVisible = true;
  bestGames: GameItem[];
  bestGameCategoryName: string = $localize`:@@bestGameCategoryName:Most played`;
  bestGamesShowMore = false;
  profileName = $localize`:@@profile-title:Profile`;
  avatar: string;
  email: string;
  verified: string;
  gamificationSubscription: Subscription;
  gamificationPercentFill: number = 0.;
  gamificationLevel: number = 1;
  playerLoyalityName: string;
  showGamification: boolean;
  chargebackFormatted: string;
  nextChargebackDate: Date;
  chargebackPercentFill: number = 0.;
  chargebackSubscription: Subscription;
  userChargeback: UserChargeback;
  userChargebackToClaim: UserChargeback;
  userBonusLotteryResponse: UserBonusLottery;
  isPrizeBoxActive = false;
  lemonIconNameIndex: number;

  readonly lotteryInfoUrl: string = '/' + this.translationConfig.getTranslation(bonusTrx) + '/' + this.translationConfig.getTranslation(lemonLotteryTermsTrx);
  readonly treasureBoxInfoUrl: string = '/' + this.translationConfig.getTranslation(infoTrx) + '/' + this.translationConfig.getTranslation(treasureBoxesTrx);
  readonly scratchCardsInfoUrl: string = '/' + this.translationConfig.getTranslation(bonusTrx) + '/' + this.translationConfig.getTranslation(scratchCardsTrx);
  readonly cashbackInfoUrl: string = '/' + this.translationConfig.getTranslation(infoTrx) + '/' + this.translationConfig.getTranslation(cashbackTrx);
  readonly editProfileUrl: string = '/' + this.translationConfig.getTranslation(editProfileTrx);
  readonly changePasswordUrl: string = '/' + this.translationConfig.getTranslation(changePasswordTrx);
  readonly bonusesUrl: string = '/' + this.translationConfig.getTranslation(clientAreaTrx) + '/' + this.translationConfig.getTranslation(bonusQueueTrx);
  readonly myGamesUrl: string = '/' + this.translationConfig.getTranslation(myGamesTrx);
  ngOnInit(): void {
    this.initialize();
  }

  protected initialize() {
    this.toggleBackService.showBack();
    this.bestGamesService.getBestPlayed().pipe(takeUntil(this.unsubscribe)).subscribe((resp) => {
      this.bestGames = resp;
    });

    this.avatarService.getAvatar().pipe(takeUntil(this.unsubscribe)).subscribe(data => this.avatar = data);

    this.loginStatusService.getLoginStatus().pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
      console.log(resp)
      this.email = resp.username;
      this.verified = resp?.fullInfo?.verification_status;
      this.subscribeToGamification();
      this.subscribeToChargeback();
      this.subscribeToBonusLottery();
    });



    this.isDesktop = this.deviceDetectService.isDesktop();
  }

  private subscribeToGamification() {
    this.gamificationSubscription = this.gamificationLevelService.getGamification().pipe(
      takeUntil(this.unsubscribe),
      filter(resp => !!resp)
    ).subscribe((resp) => {
      if (!!resp?.turnover) this.gamificationPercentFill = Math.min(100, resp?.turnover / resp?.levelEndsTurnover * 100);
      this.gamificationLevel = resp?.levelNumber;
      this.lemonIconNameIndex = this.getLemonIconNameIndex();
      this.playerLoyalityName = LEVEL_NAMES[Math.floor(this.gamificationLevel / 4)];
      this.showGamification = true;
    });

    this.prizeBoxService.getCurrentLevelLottery()
      .pipe(
        switchMap(() => this.prizeBoxService.getCurrentLevelLottery()),
        takeUntil(this.unsubscribe),
        filter(resp => resp !== null))
      .subscribe(resp => {
        this.isPrizeBoxActive = resp.isActive;
      });
  }

  private subscribeToChargeback() {
    this.userChargebackToClaim = null;
    this.chargebackSubscription = this.chargebackService.getChargeback().pipe(
      take(1),
    )
      .subscribe((resp) => {
        if (!!resp.active) {
          const currentDate = new Date();
          const chargebackDateSecs = Math.floor(((resp.active as UserChargeback).convertedDate.getTime() - currentDate.getTime())) / 1000;
          const chargebackDays2Go = (chargebackDateSecs / 60 / 60 / 24);
          this.chargebackFormatted = chargebackDays2Go.toFixed(0) + 'd:' + Math.floor(chargebackDateSecs / 60 / 60) % 24 + 'h:' + Math.floor(chargebackDateSecs / 60) % 60 + 'm';
          this.chargebackPercentFill = Math.floor((7 - chargebackDays2Go) / 7. * 100.);
          this.userChargeback = (resp.active as UserChargeback);
        }
        if (!!resp.toClaim) {
          this.userChargebackToClaim = (resp.toClaim as UserChargeback);
        }
      });
  }

  private subscribeToBonusLottery() {
    this.bonusLotteryService.getBonusLottery().pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(resp => {
      if (!!resp && resp.isActive)
        this.userBonusLotteryResponse = resp;
    });
  }

  openLotteryWheel() {
    this.router.navigate(['', 'client-area', 'lottery-wheel'], { skipLocationChange: true });
  }

  claimCashback() {
    if (!!this.userChargebackToClaim)
      this.chargebackService.claimChargeback(this.userChargebackToClaim).subscribe({
        next: (resp) => {
          this.subscribeToChargeback()
        },
        error: (error) => {
          console.log("error", error)
          this.subscribeToChargeback()
        }
      });
  }

  openPrizeBox() {
    this.router.navigate(['', 'client-area', this.translationConfig.getTranslation(prizeBoxTrx)]);
  }

  getLemonIconNameIndex(): number {
    return Math.ceil(this.gamificationLevel / 5);
  }
}

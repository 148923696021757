import { Inject, Injectable } from '@angular/core';
import { LoginStatusService } from '../auth/login/login-status.service';
import { BaseFirebaseService } from '../http/base-firebase.service';
import { HttpClient } from '@angular/common/http';
import { LoggedStatus } from '../auth/login/login.models';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { Prize, PrizeBox, PrizeBoxesLevelLottery } from './client-area.models';
import { BehaviorSubject, Observable } from 'rxjs';
import { CURRENT_LOTTERY_LEVEL, LOTTERY_SELECT_BOX_VOLATILITY, OPEN_PRIZE_BOX } from '../http/base-http.service';
import { HOST_NAME } from 'src/app/global.tokens';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class PrizeBoxService extends BaseFirebaseService {

  constructor(
    http: HttpClient,
    afAuth: AngularFireAuth,
    afs: AngularFirestore,
    private loginStatus: LoginStatusService,
    @Inject(HOST_NAME) public hostName
  ) {
    super(http, afAuth, afs, hostName);
  }

  private _isActiveLottery = new BehaviorSubject<boolean>(false);

  isActiveLottery(): Observable<boolean> {
    return this._isActiveLottery.asObservable();
  }

  getCurrentLevelLottery(): Observable<PrizeBoxesLevelLottery> {
    return this.get(CURRENT_LOTTERY_LEVEL).pipe(
      map((resp: PrizeBoxesLevelLottery) => {
        if (resp) {
          resp.prizeBoxes?.forEach((elem, index) => {
            elem.index = index;
          })
          this._isActiveLottery.next(true);
        } else {
          this._isActiveLottery.next(false);
        }
        return resp;
      })
    )
  }

  postSelectPrizeBoxVolatilityType(lotteryLevelUID, prizeBoxTypeSelection) {
    return this.post(LOTTERY_SELECT_BOX_VOLATILITY,
      {
        lotteryLevelUID: lotteryLevelUID,
        prizeBoxTypeSelection: prizeBoxTypeSelection
      }
    ).pipe(
      take(1)
    )
  }



  // getPrizeBoxes(): Observable<PrizeBox[]> {
  //   return this.loginStatus.getLoginStatus().pipe(
  //     filter((status) => status.isLogged === LoggedStatus.logged),
  //     switchMap(
  //       (user) => {
  //         return this.afs.collection<PrizeBox>(`/prize-users/${user.username}/prize-boxes`,
  //           ref => {
  //             let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
  //             query = query.where("isOpen", "==", false);
  //             return query;
  //           }).snapshotChanges().pipe(
  //             take(1),
  //             map((docs: any) => {
  //               return docs.map(a => {
  //                 let data = a.payload.doc.data();
  //                 data.id = a.payload.doc.id;
  //                 return (data as PrizeBox);
  //               });
  //             })
  //           )
  //       }
  //     )
  //   )
  // }


  openPrizeBox(lotteryUid: string, item: PrizeBox) {
    return this.loginStatus.getLoginStatus().pipe(
      filter((status) => status.isLogged === LoggedStatus.logged),
      mergeMap(
        (status) => {
          return this.get<Prize>(OPEN_PRIZE_BOX + `/${lotteryUid}/${item.index}`);
        }
      ),
    )
  }
}
